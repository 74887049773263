import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Posts({ data }) {
  const { posts } = data.allPosts

  return (

    <Layout>
        <SEO title="Posts" />
        <h1>My posts</h1>

        {posts.map(post => (
            <article key={post.frontmatter.slug}>
                <h2><Link to={post.frontmatter.slug}>{post.frontmatter.title}</Link> <small>{post.frontmatter.date}</small></h2>
                
            </article>
            
        ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allPosts: allMarkdownRemark 
    (
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { date: { ne: null } } }
    )
    {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          slug
        }
      }
    }
  }
`